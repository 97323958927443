"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNumClozeCards = void 0;
var ClozeRegex_1 = require("../../constants/ClozeRegex");
function getNumClozeCards(textFieldValue) {
    var matches = textFieldValue.match(ClozeRegex_1.CLOZE_REGEX.CAPTURE_NUM);
    if (!matches) {
        return 1;
    }
    var numbers = matches === null || matches === void 0 ? void 0 : matches.map(function (match) {
        // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace
        // $1 is a special replacement pattern
        return match.replace(ClozeRegex_1.CLOZE_REGEX.CAPTURE_NUM, "$1");
    });
    return new Set(numbers).size;
}
exports.getNumClozeCards = getNumClozeCards;
