"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrayLast = void 0;
function arrayLast(arr) {
    if (arr.length === 0) {
        return null;
    }
    return arr[arr.length - 1];
}
exports.arrayLast = arrayLast;
