"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.exhaustiveStringArray = void 0;
// From https://stackoverflow.com/a/55266531
function exhaustiveStringArray() {
    return function () {
        var x = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            x[_i] = arguments[_i];
        }
        return x;
    };
}
exports.exhaustiveStringArray = exhaustiveStringArray;
