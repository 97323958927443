import {
  DimensionsContext,
  DimensionsContextData,
} from "@ui/context/DimensionsContext";

import { useContext } from "react";

export function useDimensionsContext(): DimensionsContextData {
  return useContext(DimensionsContext);
}
