"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.jsonStringify = void 0;
// From https://github.com/GoogleChromeLabs/jsbi/issues/30
function jsonStringifyBigint(object) {
    return JSON.stringify(object, function (_key, value) {
        return typeof value === "bigint" ? value.toString() : value;
    });
}
// Adapted from https://gist.github.com/bennettmcelwee/06f0cadd6a41847f848b4bd2a351b6bc
function jsonStringifyMaxDepth(obj, depth) {
    if (!obj || typeof obj !== "object") {
        return jsonStringifyBigint(obj);
    }
    if (depth === 0) {
        return '"<?>"';
    }
    var recursiveResult = Object.keys(obj)
        .map(function (key) {
        var val = jsonStringifyMaxDepth(obj[key], depth - 1);
        return "\"".concat(key, "\":").concat(val);
    })
        .join(",");
    return "{".concat(recursiveResult, "}");
}
// From https://github.com/GoogleChromeLabs/jsbi/issues/30
// eslint-disable-next-line @typescript-eslint/ban-types
function jsonStringify(object, depth) {
    if (depth == null) {
        return jsonStringifyBigint(object);
    }
    return jsonStringifyMaxDepth(object, depth);
}
exports.jsonStringify = jsonStringify;
