/* eslint-disable react/jsx-no-constructed-context-values */
import { emptyFunction, Maybe, MaybeUndef } from "shared";
import { RelayConnection } from "@web/types/enums/RelayConnection";
import {
  Context,
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import RelayKeyedConnection from "@web/types/enums/RelayKeyedConnection";

export type RelayConnectionIdsContextData = {
  [key in RelayConnection]: {
    connectionId: Maybe<string>;
    setConnectionId: (val: string) => void;
  };
} & {
  [key in RelayKeyedConnection]: {
    allConnectionIds: Array<string>;
    allConnectionIdsWithKeys: Array<{ connectionId: string; key: string }>;
    getConnectionId: (key: string) => MaybeUndef<string>;
    setConnectionId: (key: string, val: string) => void;
  };
};

export const RelayConnectionIdsContext: Context<RelayConnectionIdsContextData> =
  createContext<RelayConnectionIdsContextData>({
    [RelayKeyedConnection.CardsForDeckUrlTitle]: {
      allConnectionIds: [],
      allConnectionIdsWithKeys: [],
      getConnectionId: (_key: string) => null,
      setConnectionId: emptyFunction,
    },
    [RelayKeyedConnection.CardsToStudyForDeckUrlTitle]: {
      allConnectionIds: [],
      allConnectionIdsWithKeys: [],
      getConnectionId: (_key: string) => null,
      setConnectionId: emptyFunction,
    },
    [RelayConnection.DecksForViewer]: {
      connectionId: null,
      setConnectionId: emptyFunction,
    },
    [RelayConnection.ExploreDecks]: {
      connectionId: null,
      setConnectionId: emptyFunction,
    },
  });

function useMemoizedSetter(
  setter: Dispatch<SetStateAction<Record<string, string>>>
) {
  const memoizedSetter = useMemo(
    () => (key: string, val: string) =>
      setter((old) => ({
        ...old,
        [key]: val,
      })),
    [setter]
  );

  return memoizedSetter;
}

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export function RelayConnectionIdsProvider(props: ProviderProps): JSX.Element {
  const [
    cardsForDeckUrlTitleConnectionIds,
    setCardsForDeckUrlTitleConnectionIds,
  ] = useState<Record<string, string>>({});
  const [
    cardsToStudyForDeckUrlTitleConnectionIds,
    setCardsToStudyForDeckUrlTitleConnectionIds,
  ] = useState<Record<string, string>>({});
  const [decksForViewerConnectionId, setDecksForViewerConnectionId] =
    useState<Maybe<string>>(null);
  const [exploreDecksConnectionId, setExploreDecksConnectionId] =
    useState<Maybe<string>>(null);

  // Why memoize? Consider this code:
  //
  // useEffect(() => {
  //   setCardsToStudyForDeckUrlTitleConnectionId(
  //     deck!.id,
  //     connection.__id
  //   );
  // }, [connection.__id, deck, setCardsToStudyForDeckUrlTitleConnectionId]);
  //
  // If setCardsToStudyForDeckUrlTitleConnectionId is not memoized, then upon calling
  // setCardsToStudyForDeckUrlTitleConnectionId, setCardsToStudyForDeckUrlTitleConnectionId will
  // be set to a new function, which will cause the useEffect to run again, ad infinitum.
  const setCardsForDeckUrlTitleConnectionIdsMemoized = useMemoizedSetter(
    setCardsForDeckUrlTitleConnectionIds
  );
  const setCardsToStudyForDeckUrlTitleConnectionIdsMemoized = useMemoizedSetter(
    setCardsToStudyForDeckUrlTitleConnectionIds
  );

  const value = useMemo(
    () => ({
      [RelayKeyedConnection.CardsForDeckUrlTitle]: {
        allConnectionIds: Object.values(cardsForDeckUrlTitleConnectionIds),
        allConnectionIdsWithKeys: Object.keys(
          cardsForDeckUrlTitleConnectionIds
        ).map((key) => ({
          connectionId: cardsForDeckUrlTitleConnectionIds[key],
          key,
        })),
        getConnectionId: (key: string) =>
          cardsForDeckUrlTitleConnectionIds[key],
        setConnectionId: setCardsForDeckUrlTitleConnectionIdsMemoized,
      },
      [RelayKeyedConnection.CardsToStudyForDeckUrlTitle]: {
        allConnectionIds: Object.values(
          cardsToStudyForDeckUrlTitleConnectionIds
        ),
        allConnectionIdsWithKeys: Object.keys(
          cardsToStudyForDeckUrlTitleConnectionIds
        ).map((key) => ({
          connectionId: cardsToStudyForDeckUrlTitleConnectionIds[key],
          key,
        })),
        getConnectionId: (key: string) =>
          cardsToStudyForDeckUrlTitleConnectionIds[key],
        setConnectionId: setCardsToStudyForDeckUrlTitleConnectionIdsMemoized,
      },
      [RelayConnection.DecksForViewer]: {
        connectionId: decksForViewerConnectionId,
        setConnectionId: setDecksForViewerConnectionId,
      },
      [RelayConnection.ExploreDecks]: {
        connectionId: exploreDecksConnectionId,
        setConnectionId: setExploreDecksConnectionId,
      },
    }),
    [
      cardsForDeckUrlTitleConnectionIds,
      cardsToStudyForDeckUrlTitleConnectionIds,
      decksForViewerConnectionId,
      exploreDecksConnectionId,
      setCardsForDeckUrlTitleConnectionIdsMemoized,
      setCardsToStudyForDeckUrlTitleConnectionIdsMemoized,
    ]
  );

  return (
    <RelayConnectionIdsContext.Provider value={value}>
      {props.children}
    </RelayConnectionIdsContext.Provider>
  );
}
