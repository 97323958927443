"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.objectSwapKeysAndValues = void 0;
var objectEntries_1 = require("./objectEntries");
var objectFromEntries_1 = require("./objectFromEntries");
// NOTE: values should be unique, otherwise some key/value pairs
// will be lost
function objectSwapKeysAndValues(obj) {
    var swapped = (0, objectEntries_1.objectEntries)(obj).map(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        return [
            value,
            key,
        ];
    });
    return (0, objectFromEntries_1.objectFromEntries)(swapped);
}
exports.objectSwapKeysAndValues = objectSwapKeysAndValues;
