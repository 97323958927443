"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCompareByProperty = void 0;
var SortOrder_1 = require("../../types/enums/SortOrder");
function getCompareByProperty(key, order) {
    if (order === void 0) { order = SortOrder_1.SortOrder.Asc; }
    return function (firstEl, secondEl) {
        if (firstEl[key] == null) {
            return 1;
        }
        if (secondEl[key] == null) {
            return -1;
        }
        if (firstEl[key] < secondEl[key]) {
            return order === SortOrder_1.SortOrder.Asc ? -1 : 1;
        }
        if (firstEl[key] > secondEl[key]) {
            return order === SortOrder_1.SortOrder.Asc ? 1 : -1;
        }
        return 0;
    };
}
exports.getCompareByProperty = getCompareByProperty;
