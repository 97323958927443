"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidUrl = void 0;
function isValidUrl(str) {
    try {
        var parsedUrl = new URL(str);
        return parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
    }
    catch (err) {
        return false;
    }
}
exports.isValidUrl = isValidUrl;
