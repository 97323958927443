import { getTracesSampleRate } from "@web/utils/analytics/getTracesSampleRate";
import { sentryBeforeSend } from "@web/utils/analytics/sentryBeforeSend";
import { getEnvironment } from "@web/utils/env/getEnvironment";

export const SENTRY_SHARED_OPTIONS = {
  attachStacktrace: true,
  beforeSend: sentryBeforeSend,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: getEnvironment(),
  tracesSampleRate: getTracesSampleRate(),
};
