"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSessionFlagCookieName = void 0;
var getSessionCookieName_1 = require("./getSessionCookieName");
// The real session cookie is HTTP-only, and thus cannot be accessed via JS.
// This cookie is NOT HTTP-only, and can be used by the frontend to determine
// if the user is logged in.
//
// Inspired by https://stackoverflow.com/a/63877877.
function getSessionFlagCookieName(environment) {
    return (0, getSessionCookieName_1.getSessionCookieName)(environment) + "-flag";
}
exports.getSessionFlagCookieName = getSessionFlagCookieName;
