"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteTypeName = void 0;
// Default note type names. Custom ones are not enumerated here.
var NoteTypeName;
(function (NoteTypeName) {
    NoteTypeName["Basic"] = "Basic";
    NoteTypeName["BasicWithReversed"] = "BasicWithReversed";
    NoteTypeName["Cloze"] = "Cloze";
})(NoteTypeName = exports.NoteTypeName || (exports.NoteTypeName = {}));
