import { Environment } from "shared";
import { isDevSubdomain } from "@web/utils/env/isDevSubdomain";
import { isLocalhost } from "@web/utils/env/isLocalhost";

export function getEnvironment(): Environment {
  if (isLocalhost()) {
    return Environment.Local;
  }

  if (isDevSubdomain()) {
    return Environment.Development;
  }

  switch (process.env.NEXT_PUBLIC_ENV) {
    case "local":
      return Environment.Local;
    case "development":
      return Environment.Development;
    case "test":
      return Environment.Test;
    default:
      return Environment.Production;
  }
}
