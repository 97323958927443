import { useWindowDimensions } from "@ui/hooks/dimensions/useWindowDimensions";
import { Breakpoint } from "@ui/types/enums/Breakpoint";

export const BOTTOM_TABS_WIDTH = Breakpoint.Width768;

export function useIsBottomTabsWidth(): boolean {
  const { width } = useWindowDimensions();

  return width <= BOTTOM_TABS_WIDTH;
}
