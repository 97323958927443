"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeDuplicatesByProperty = void 0;
var removeDuplicatesWithComparison_1 = require("./removeDuplicatesWithComparison");
/**
 * Uniquifies the passed-in array of objects based on the passed-in key.
 *
 * @param arr An array of objects.
 * @param key A key that exists in each object.
 */
function removeDuplicatesByProperty(arr, key) {
    return (0, removeDuplicatesWithComparison_1.removeDuplicatesWithComparison)(arr, function (a, b) { return a[key] === b[key]; });
}
exports.removeDuplicatesByProperty = removeDuplicatesByProperty;
