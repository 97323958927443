"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlParam = void 0;
var UrlParam;
(function (UrlParam) {
    UrlParam["EnableErrorLogs"] = "enable_error_logs";
    UrlParam["GoogleIdToken"] = "token";
    UrlParam["Nonce"] = "nonce";
    UrlParam["FirebaseToken"] = "firebase_token";
})(UrlParam = exports.UrlParam || (exports.UrlParam = {}));
