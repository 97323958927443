"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCompareByPropertyFunction = void 0;
var SortOrder_1 = require("../../types/enums/SortOrder");
function getCompareByPropertyFunction(key, fn, order) {
    if (order === void 0) { order = SortOrder_1.SortOrder.Asc; }
    return function (firstEl, secondEl) {
        if (firstEl[key] == null) {
            return 1;
        }
        if (secondEl[key] == null) {
            return -1;
        }
        var firstVal = fn(firstEl[key]);
        var secondVal = fn(secondEl[key]);
        if (firstVal < secondVal) {
            return order === SortOrder_1.SortOrder.Asc ? -1 : 1;
        }
        if (firstVal > secondVal) {
            return order === SortOrder_1.SortOrder.Asc ? 1 : -1;
        }
        return 0;
    };
}
exports.getCompareByPropertyFunction = getCompareByPropertyFunction;
