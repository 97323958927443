"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAlphabetNonce = void 0;
var nanoid_1 = require("nanoid");
function getAlphabetNonce(numCharacters) {
    if (numCharacters === void 0) { numCharacters = 4; }
    var nanoid = (0, nanoid_1.customAlphabet)("abcdefghijklmnopqrstuvwxyz", numCharacters);
    var nonce = nanoid();
    return nonce;
}
exports.getAlphabetNonce = getAlphabetNonce;
