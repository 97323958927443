import { MaybeUndef, CustomHeader, Environment } from "shared";
import { Variables } from "react-relay";
import https from "https";
import { isBrowser } from "@web/utils/env/isBrowser";
import { getEnvironment } from "@web/utils/env/getEnvironment";
import axios from "axios";

export async function fetchGraphql(
  name: string,
  text: MaybeUndef<string>,
  variables: Variables
): Promise<any> {
  const body = JSON.stringify({
    operationName: name,
    query: text,
    variables,
  });
  const headers = {
    "Content-Type": "application/json",
    [CustomHeader.OperationName]: name,
  };
  const url = process.env.NEXT_PUBLIC_GRAPHQL_URL as string;

  if (!isBrowser() && getEnvironment() === Environment.Local) {
    // Needed to avoid "Error: request to https://localhost:4000/graphql failed, reason: unable to verify the first certificate"
    // when this code runs on the Next.js server during pre-rendering.
    //
    // Note that we only need this because we're using a custom certificate so that the server can run
    // on https.
    const httpsAgent = new https.Agent({ rejectUnauthorized: false });
    const response = await axios({
      data: body,
      headers,
      httpsAgent: httpsAgent,
      method: "POST",
      url,
      withCredentials: true,
    });
    return response.data;
  }

  const response = await fetch(url, {
    body,
    credentials: "include",
    headers,
    method: "POST",
  });
  return response.json();
}
