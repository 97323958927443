"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringLast = void 0;
function stringLast(str) {
    if (str.length === 0) {
        return "";
    }
    return str[str.length - 1];
}
exports.stringLast = stringLast;
