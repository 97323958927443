/* eslint-disable react-hooks/exhaustive-deps */
import { Context, createContext, useEffect, useMemo, useState } from "react";
import { Dimensions } from "@ui/types/Dimensions";
import { BOTTOM_TABS_WIDTH } from "@ui/hooks/dimensions/useIsBottomTabsWidth";
import { useDimensions } from "@ui/hooks/dimensions/useDimensions";

function dummyFunctionForServer() {
  return {
    height: 0,
    // So bottom tabs are not shown on hydration
    width: BOTTOM_TABS_WIDTH + 1,
  };
}

// Returns dimensions of document.body which does not include things like
// scrollbar width
function getDocumentBodyDimensions() {
  const { clientWidth: width, clientHeight: height } = document.body;
  return {
    height,
    width,
  };
}

// Returns dimensions of the viewport which includes things like
// scrollbar width
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    height,
    width,
  };
}

export type DimensionsContextData = {
  documentBodyDimensions: Dimensions;
  windowDimensions: Dimensions;
};

export const DimensionsContext: Context<DimensionsContextData> =
  createContext<DimensionsContextData>({
    documentBodyDimensions: dummyFunctionForServer(),
    windowDimensions: dummyFunctionForServer(),
  });

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

/**
 * We do this in a context instead of standalone hooks so that dummyFunctionForServer
 * only runs once on the client, as opposed to every time the standalone hook is called (which
 * would then cause the dummy dimensions to be used when they shouldn't be).
 */
export function DimensionsContextProvider(props: ProviderProps): JSX.Element {
  const [documentBodyFn, setDocumentBodyFn] = useState<() => Dimensions>(
    () => dummyFunctionForServer
  );
  const [windowFn, setWindowFn] = useState<() => Dimensions>(
    () => dummyFunctionForServer
  );

  useEffect(() => {
    setDocumentBodyFn(() => getDocumentBodyDimensions);
  }, []);
  useEffect(() => {
    setWindowFn(() => getWindowDimensions);
  }, []);

  const documentBodyDimensions = useDimensions(documentBodyFn);
  const windowDimensions = useDimensions(windowFn);

  const value = useMemo(
    () => ({
      documentBodyDimensions,
      windowDimensions,
    }),
    [documentBodyDimensions, windowDimensions]
  );

  return (
    <DimensionsContext.Provider value={value}>
      {props.children}
    </DimensionsContext.Provider>
  );
}
