"use strict";
/**
 * This module is adapted from https://github.com/m59peacemaker/js-object.entries
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.objectEntries = void 0;
function has(obj, prop) {
    return Object.prototype.hasOwnProperty.call(obj, prop);
}
function isEnumerable(obj, prop) {
    return Object.prototype.propertyIsEnumerable.call(obj, prop);
}
function objectEntries(obj) {
    var pairs = [];
    for (var key in obj) {
        if (has(obj, key) && isEnumerable(obj, key)) {
            pairs.push([key, obj[key]]);
        }
    }
    return pairs;
}
exports.objectEntries = objectEntries;
