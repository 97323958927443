"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExploreCategory = void 0;
// TODO: replace with GraphQL enum
var ExploreCategory;
(function (ExploreCategory) {
    ExploreCategory["Chinese"] = "Chinese";
    ExploreCategory["English"] = "English";
    ExploreCategory["Japanese"] = "Japanese";
})(ExploreCategory = exports.ExploreCategory || (exports.ExploreCategory = {}));
