"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HOUR_IN_DAY = exports.SEC_IN_4_DAY = exports.SEC_IN_DAY = exports.SEC_IN_HOUR = exports.SEC_IN_15_MIN = exports.SEC_IN_10_MIN = exports.SEC_IN_MIN = exports.MS_IN_SEC = void 0;
exports.MS_IN_SEC = 1000;
exports.SEC_IN_MIN = 60;
exports.SEC_IN_10_MIN = 10 * exports.SEC_IN_MIN;
exports.SEC_IN_15_MIN = 15 * exports.SEC_IN_MIN;
exports.SEC_IN_HOUR = 60 * exports.SEC_IN_MIN;
exports.SEC_IN_DAY = 24 * exports.SEC_IN_HOUR;
exports.SEC_IN_4_DAY = 4 * exports.SEC_IN_DAY;
exports.HOUR_IN_DAY = 24;
