"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardTypeName = void 0;
// Default card type names. Custom ones are not enumerated here.
var CardTypeName;
(function (CardTypeName) {
    CardTypeName["Basic"] = "Basic";
    CardTypeName["BasicReversed"] = "BasicReversed";
    CardTypeName["Cloze"] = "Cloze";
})(CardTypeName = exports.CardTypeName || (exports.CardTypeName = {}));
