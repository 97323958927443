import {
  Environment,
  FetchFunction,
  Network,
  Observable,
  RecordSource,
  Store,
  SubscribeFunction,
} from "relay-runtime";

import { fetchGraphql } from "@web/utils/graphql/fetchGraphql";
import { createClient } from "graphql-ws";
import { isBrowser } from "@web/utils/env/isBrowser";

/**
 * Relay requires developers to configure a "fetch" function that tells Relay how to load
 * the results of GraphQL queries from your server (or other data source). See more at
 * https://relay.dev/docs/en/quick-start-guide#relay-environment.
 */
const fetchRelay: FetchFunction = async (params, variables, _cacheConfig) => {
  const response = await fetchGraphql(params.name, params.text, variables);

  // GraphQL returns exceptions (for example, a missing required variable) in the "errors"
  // property of the response. If any exceptions occurred when processing the request,
  // throw an error to indicate to the user what went wrong.
  if (Array.isArray(response.errors)) {
    throw response.errors[0];
  }

  return response;
};

function getSubscribeFn() {
  if (!isBrowser()) {
    return undefined;
  }

  const wsClient = createClient({
    url: process.env.NEXT_PUBLIC_WS_GRAPHQL_URL as string,
  });

  const subscribe: SubscribeFunction = (operation, variables) => {
    return Observable.create((sink) => {
      return wsClient.subscribe(
        {
          operationName: operation.name,
          query: operation.text!,
          variables,
        },
        // @ts-ignore TODO: fix this
        sink
      );
    });
  };

  return subscribe;
}
export const RelayEnvironment = new Environment({
  network: Network.create(fetchRelay, getSubscribeFn()),
  store: new Store(new RecordSource(), {
    // This property tells Relay to not immediately clear its cache when the user
    // navigates around the app. Relay will hold onto the specified number of
    // query results, allowing the user to return to recently visited pages
    // and reusing cached data if its available/fresh.
    gcReleaseBufferSize: 20,
  }),
});
