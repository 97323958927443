/* eslint-disable relay/unused-fields */
import {
  ViewerContextQuery,
  ViewerContextQuery$data,
} from "@web/__generated__/ViewerContextQuery.graphql";
import { onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import {
  Context,
  createContext,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { MaybeUndef, emptyFunction } from "shared";
import { getFirebaseAuth } from "@web/utils/firebase/initializeFirebase";

const query = graphql`
  query ViewerContextQuery {
    UserQueries {
      viewer {
        noteTypes {
          id
          cardTypes {
            id
            backTemplate
            frontTemplate
            name
          }
          fieldNames
          name
        }
        viewer {
          id
          email
          globalSettings {
            reviewRefreshTime
            timeZoneName
          }
          username
        }
      }
    }
  }
`;

export type NoteType = Exclude<
  ViewerContextQuery$data["UserQueries"]["viewer"]["noteTypes"],
  null
>[number];

export type Viewer = Exclude<
  ViewerContextQuery$data["UserQueries"]["viewer"]["viewer"],
  null
>;

export type ViewerContextData = {
  isLoggingIn: boolean;
  noteTypes: ReadonlyArray<NoteType>;
  setIsLoggingIn: (val: boolean) => void;
  // Undefined if viewer has not been loaded yet. Null if viewer is not logged in.
  viewer: MaybeUndef<Viewer>;
  viewerFirebaseInfo: MaybeUndef<FirebaseUser>;
};

export const ViewerContext: Context<ViewerContextData> =
  createContext<ViewerContextData>({
    isLoggingIn: false,
    noteTypes: [],
    setIsLoggingIn: emptyFunction,
    viewer: undefined,
    viewerFirebaseInfo: undefined,
  });

function LoadQuery({
  setNoteTypes,
  setViewer,
}: {
  setNoteTypes: (val: ReadonlyArray<NoteType>) => void;
  setViewer: (val: MaybeUndef<Viewer>) => void;
}) {
  const {
    UserQueries: {
      viewer: { noteTypes, viewer },
    },
  } = useLazyLoadQuery<ViewerContextQuery>(query, {});
  useEffect(() => {
    setNoteTypes(noteTypes ?? []);
    setViewer(viewer);
  }, [noteTypes, setNoteTypes, setViewer, viewer]);

  return null;
}

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export function ViewerContextProvider(props: ProviderProps): JSX.Element {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [noteTypes, setNoteTypes] = useState<ReadonlyArray<NoteType>>([]);
  const [viewer, setViewer] = useState<MaybeUndef<Viewer>>(undefined);
  const [viewerFirebaseInfo, setViewerFirebaseInfo] =
    useState<MaybeUndef<FirebaseUser>>(undefined);
  const value = useMemo(
    () => ({
      isLoggingIn,
      noteTypes,
      setIsLoggingIn,
      viewer,
      viewerFirebaseInfo,
    }),
    [isLoggingIn, noteTypes, viewer, viewerFirebaseInfo]
  );
  onAuthStateChanged(getFirebaseAuth(), (user) => {
    setViewerFirebaseInfo(user);
  });

  return (
    <ViewerContext.Provider value={value}>
      <Suspense fallback={null}>
        <LoadQuery setNoteTypes={setNoteTypes} setViewer={setViewer} />
      </Suspense>
      {props.children}
    </ViewerContext.Provider>
  );
}
