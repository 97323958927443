import { ErrorEvent } from "@sentry/types";
import { AnalyticsEvent } from "@web/types/enums/AnalyticsEvent";
import { getSentryEventSeverity } from "@web/utils/analytics/getSentryEventSeverity";

export function sentryBeforeSend(event: ErrorEvent) {
  // eslint-disable-next-line no-param-reassign
  event.tags = { event: AnalyticsEvent.TopLevelEvent, ...(event.tags ?? {}) };

  const severity = getSentryEventSeverity(event);
  if (severity != null) {
    // eslint-disable-next-line no-param-reassign
    event.level = severity;
  }

  return event;
}
