"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DECK_STUDY_FIELDS = exports.FACTOR_EASE_DEFAULT = exports.DECK_MIN_TITLE_LENGTH = exports.DECK_MIN_DESCRIPTION_LENGTH = exports.DECK_MAX_DESCRIPTION_LENGTH = exports.DECK_MAX_TITLE_LENGTH = void 0;
var TimeConstants_1 = require("./TimeConstants");
exports.DECK_MAX_TITLE_LENGTH = 32;
exports.DECK_MAX_DESCRIPTION_LENGTH = 300;
exports.DECK_MIN_DESCRIPTION_LENGTH = 0;
exports.DECK_MIN_TITLE_LENGTH = 1;
exports.FACTOR_EASE_DEFAULT = 250;
var FACTOR_EASY_BONUS = 130;
var FACTOR_LAPSED = 0;
var INTERVAL_LAPSED_MINIMUM = TimeConstants_1.SEC_IN_DAY;
var INTERVAL_LEARN_EASY_GRADUATION = TimeConstants_1.SEC_IN_4_DAY;
var INTERVAL_LEARN_GOOD_GRADUATION = TimeConstants_1.SEC_IN_DAY;
var STEPS_LAPSED = [TimeConstants_1.SEC_IN_10_MIN];
var STEPS_LEARN = [TimeConstants_1.SEC_IN_MIN, TimeConstants_1.SEC_IN_10_MIN];
exports.DECK_STUDY_FIELDS = {
    factorEaseDefault: exports.FACTOR_EASE_DEFAULT,
    factorEasyBonus: FACTOR_EASY_BONUS,
    factorLapsed: FACTOR_LAPSED,
    intervalLapsedMinimum: INTERVAL_LAPSED_MINIMUM,
    intervalLearnEasyGraduation: INTERVAL_LEARN_EASY_GRADUATION,
    intervalLearnGoodGraduation: INTERVAL_LEARN_GOOD_GRADUATION,
    stepsLapsed: {
        set: STEPS_LAPSED,
    },
    stepsLearn: {
        set: STEPS_LEARN,
    },
};
