"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nextReviewRefreshTime = void 0;
var dayjsex_1 = require("../dates/dayjsex");
var reviewRefreshTime_1 = require("./reviewRefreshTime");
var reviewRefreshTimeTomorrow_1 = require("./reviewRefreshTimeTomorrow");
/**
 * Based on the current or passed-in time, returns the next review refresh time.
 *
 * Specifically, there are two possible scenarios:
 * 1) If the time is before reviewRefreshTime(...), then that review refresh time
 *    should be returned.
 * 2) If the time is after reviewRefreshTime(...), then the review refresh time for
 *    tomorrow should be returned.
 */
function nextReviewRefreshTime(reviewRefreshTimeSettings, date) {
    var dateToUse = date !== null && date !== void 0 ? date : (0, dayjsex_1.dayjsex)();
    var todayReviewRefreshTime = (0, reviewRefreshTime_1.reviewRefreshTime)(reviewRefreshTimeSettings, dateToUse);
    var tomorrowReviewRefreshTime = (0, reviewRefreshTimeTomorrow_1.reviewRefreshTimeTomorrow)(reviewRefreshTimeSettings, dateToUse);
    return dateToUse.isBefore(todayReviewRefreshTime)
        ? todayReviewRefreshTime
        : tomorrowReviewRefreshTime;
}
exports.nextReviewRefreshTime = nextReviewRefreshTime;
