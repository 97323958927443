"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fieldsObjectToArray = void 0;
function fieldsObjectToArray(obj) {
    return Object.keys(obj).map(function (fieldName) { return ({
        name: fieldName,
        value: obj[fieldName],
    }); });
}
exports.fieldsObjectToArray = fieldsObjectToArray;
