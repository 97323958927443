/**
 * @generated SignedSource<<5d0151e98afd3b85007d7b2adef64472>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ViewerContextQuery$variables = {};
export type ViewerContextQuery$data = {
  readonly UserQueries: {
    readonly viewer: {
      readonly noteTypes: ReadonlyArray<{
        readonly cardTypes: ReadonlyArray<{
          readonly backTemplate: string;
          readonly frontTemplate: string;
          readonly id: string;
          readonly name: string;
        }>;
        readonly fieldNames: ReadonlyArray<string>;
        readonly id: string;
        readonly name: string;
      }> | null;
      readonly viewer: {
        readonly email: string | null;
        readonly globalSettings: {
          readonly reviewRefreshTime: string;
          readonly timeZoneName: string;
        };
        readonly id: string;
        readonly username: string;
      } | null;
    };
  };
};
export type ViewerContextQuery = {
  response: ViewerContextQuery$data;
  variables: ViewerContextQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "NoteType",
  "kind": "LinkedField",
  "name": "noteTypes",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CardType",
      "kind": "LinkedField",
      "name": "cardTypes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "backTemplate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "frontTemplate",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fieldNames",
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reviewRefreshTime",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeZoneName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewerContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserQueriesResponse",
        "kind": "LinkedField",
        "name": "UserQueries",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerResponse",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Viewer",
                "kind": "LinkedField",
                "name": "viewer",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GlobalSettings",
                    "kind": "LinkedField",
                    "name": "globalSettings",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ViewerContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserQueriesResponse",
        "kind": "LinkedField",
        "name": "UserQueries",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerResponse",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Viewer",
                "kind": "LinkedField",
                "name": "viewer",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GlobalSettings",
                    "kind": "LinkedField",
                    "name": "globalSettings",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9fcf30760282278cc3befe8c4c722001",
    "id": null,
    "metadata": {},
    "name": "ViewerContextQuery",
    "operationKind": "query",
    "text": "query ViewerContextQuery {\n  UserQueries {\n    viewer {\n      noteTypes {\n        id\n        cardTypes {\n          id\n          backTemplate\n          frontTemplate\n          name\n        }\n        fieldNames\n        name\n      }\n      viewer {\n        id\n        email\n        globalSettings {\n          reviewRefreshTime\n          timeZoneName\n          id\n        }\n        username\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e04343ab4e3b127da1e04531a44d4a87";

export default node;
