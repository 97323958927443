"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reviewRefreshTime = void 0;
var dayjsex_1 = require("../dates/dayjsex");
/**
 * Returns the time for the passed-in date that the review refreshes.
 *
 * Takes the user's time zone into account.
 */
function reviewRefreshTime(reviewRefreshTimeSettings, date) {
    var currentDateForTimeZone = date
        .tz(reviewRefreshTimeSettings.timeZoneName)
        .format("YYYY-MM-DD");
    var reviewRefreshHourOfDay = (0, dayjsex_1.dayjsex)(reviewRefreshTimeSettings.time)
        .tz(reviewRefreshTimeSettings.timeZoneName)
        .hour();
    return dayjsex_1.dayjsex.tz("".concat(currentDateForTimeZone, " ").concat(reviewRefreshHourOfDay), reviewRefreshTimeSettings.timeZoneName);
}
exports.reviewRefreshTime = reviewRefreshTime;
