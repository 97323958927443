import "@ui/css/Antd.css";
import "@ui/css/Global.css";
import "@ui/css/colors/BackgroundColorClasses.css";
import "@ui/css/colors/ColorClasses.css";
import "@ui/css/colors/ColorVariables.css";
import "@ui/css/colors/ColorVariablesDarkMode.css";
import "@ui/css/colors/ColorVariablesLightMode.css";
import "@ui/css/fonts/FontClasses.css";
import "@ui/css/fonts/FontSizes.css";
import "@ui/css/fonts/FontVariables.css";
import "@ui/css/shadows/ShadowVariables.css";
import "@ui/css/z-index/ZIndexVariables.css";
import "codemirror/theme/idea.css";
import "../css/CardDisplay.css";
import "../css/CardFrontColumn.css";
import "../css/Codemirror.css";
import "../css/GoogleLoginButton.css";
import "../css/Prosemirror.css";
import "../css/ProsemirrorCustom.css";
import { DM_Sans, Work_Sans } from "next/font/google";
import { AppProps } from "next/app";
import { RelayEnvironmentProvider } from "react-relay";
import { RelayEnvironment } from "@web/utils/graphql/RelayEnvironment";
import { ViewerContextProvider } from "@web/contexts/ViewerContext";
import { RelayConnectionIdsProvider } from "@web/contexts/RelayConnectionIdsContext";
import { DimensionsContextProvider } from "@ui/context/DimensionsContext";
import Head from "next/head";
import { Analytics } from "@vercel/analytics/react";

const dmSans = DM_Sans({
  subsets: ["latin"],
  weight: ["400", "500", "700"],
});

const workSans = Work_Sans({
  subsets: ["latin"],
  weight: ["400", "500", "700"],
});

const META_DESCRIPTION =
  "Harken is a smart flashcard app that uses spaced repetition and active recall to make remembering easy.";
const META_IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/soki-300523.appspot.com/o/landing_page%2Fharken-twitter-preview.png?alt=media&token=d063d4f1-a6db-438c-b8bd-85b6803aa7a7";
const META_TITLE = "Harken. Never forget what you learn again.";

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      {/* For some reason, this works, but specifying `variable` in the font constructor does not */}
      <style jsx global>{`
        :root {
          --dm-sans: ${dmSans.style.fontFamily};
          --work-sans: ${workSans.style.fontFamily};
        }
      `}</style>
      <Head>
        <title>Harken</title>
        <meta name="description" content={META_DESCRIPTION} />
        <meta
          name="viewport"
          // maximum-scale: https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
          content="initial-scale=1.0, width=device-width, maximum-scale=1"
        />

        {/* Facebook */}
        <meta property="og:url" content="https://harkenapp.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={META_TITLE} />
        <meta property="og:description" content={META_DESCRIPTION} />
        <meta property="og:image" content={META_IMAGE} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@WithHarken" />
        <meta name="twitter:title" content={META_TITLE} />
        <meta name="twitter:description" content={META_DESCRIPTION} />
        <meta name="twitter:image" content={META_IMAGE} />

        <link
          rel="apple-touch-icon"
          href="https://firebasestorage.googleapis.com/v0/b/soki-300523.appspot.com/o/apple-touch-icon.png?alt=media&token=8e1b7560-a1c3-483f-9878-3e688d17f62a"
        />
      </Head>
      <DimensionsContextProvider>
        <Analytics />
        <RelayEnvironmentProvider environment={RelayEnvironment}>
          <RelayConnectionIdsProvider>
            <ViewerContextProvider>
              <Component {...pageProps} />
            </ViewerContextProvider>
          </RelayConnectionIdsProvider>
        </RelayEnvironmentProvider>
      </DimensionsContextProvider>
    </>
  );
}
