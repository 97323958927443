"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reviewRefreshTimeTomorrow = void 0;
var dayjsex_1 = require("../dates/dayjsex");
var reviewRefreshTime_1 = require("./reviewRefreshTime");
/**
 * Returns the time tomorrow that the review refreshes.
 *
 * Takes the user's time zone into account.
 */
function reviewRefreshTimeTomorrow(reviewRefreshTimeSettings, date) {
    var todayReviewRefreshTime = (0, reviewRefreshTime_1.reviewRefreshTime)(reviewRefreshTimeSettings, date !== null && date !== void 0 ? date : (0, dayjsex_1.dayjsex)());
    return todayReviewRefreshTime.add(1, "day");
}
exports.reviewRefreshTimeTomorrow = reviewRefreshTimeTomorrow;
