"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupBy = void 0;
/**
 * Takes an array and groups its values by a key derived using `fn`
 *
 * e.g., groupBy(
 *   [
 *     {type: "1", name: "orange"},
 *     {type: "1", name: "apple"},
 *     {type: "2", name: "cake"}
 *   ],
 *   (o) => o.type
 * )
 *   => {"1": [{type: "1", name: "orange"}, {type: "1", name: "apple"}], "2": [{type:"2", name: "cake"}]}
 */
function groupBy(arr, fn) {
    return arr.reduce(function (acc, curr) {
        var keyVal = fn(curr);
        if (typeof keyVal !== "string") {
            return acc;
        }
        if (!(keyVal in acc)) {
            acc[keyVal] = [curr];
            return acc;
        }
        acc[keyVal].push(curr);
        return acc;
    }, {});
}
exports.groupBy = groupBy;
