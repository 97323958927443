/* eslint-disable import/prefer-default-export */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { Analytics, getAnalytics } from "firebase/analytics";
import { FirebaseStorage, getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCnrwtMm73oK-SIZMqThGb-B2v35_Y6Qss",
  appId: "1:680424354121:web:aadd24ea62cf23dab9aa98",
  authDomain: "soki-300523.firebaseapp.com",
  measurementId: "G-8W9C8LDZLQ",
  messagingSenderId: "680424354121",
  projectId: "soki-300523",
  storageBucket: "soki-300523.appspot.com",
};

// See this link for available libraries
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
const app = initializeApp(firebaseConfig);

let firebaseAuth: Auth;
export function getFirebaseAuth() {
  if (firebaseAuth != null) {
    return firebaseAuth;
  }
  firebaseAuth = getAuth(app);
  return firebaseAuth;
}

let firebaseAnalytics: Analytics;
export function getFirebaseAnalytics() {
  if (firebaseAnalytics != null) {
    return firebaseAnalytics;
  }
  firebaseAnalytics = getAnalytics(app);
  return firebaseAnalytics;
}

// Initialize Firebase Storage
let firebaseStorage: FirebaseStorage;
export function getFirebaseStorage() {
  if (firebaseStorage != null) {
    return firebaseStorage;
  }
  firebaseStorage = getStorage(app);
  return firebaseStorage;
}
