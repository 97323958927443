"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getErrorPropertiesToLog = void 0;
// See https://nodejs.org/api/errors.html for more info on Error properties
function getErrorPropertiesToLog(error) {
    var _a;
    try {
        return typeof error === "string"
            ? { errorMessage: error }
            : {
                // @ts-ignore
                cause: error.cause,
                // @ts-ignore
                code: error.code,
                errorMessage: error.message,
                errorName: error.name,
                // E.g. gets populated when axios hits a 500 error
                errorResponseData: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data,
                // @ts-ignore Only applies to Solana tx failures—attempt to log detailed errors from RPC call
                logs: error.logs,
                stack: error.stack,
            };
    }
    catch (_b) {
        // This shouldn't happen, but just in case...
        return {
            errorMessage: "Unexpected error occurred while calling ".concat(getErrorPropertiesToLog.name),
        };
    }
}
exports.getErrorPropertiesToLog = getErrorPropertiesToLog;
